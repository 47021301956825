<template>
  <div style="color: #000;height: 100%;background: #ebedf0;">
    <div class="biao_ti">
      <van-icon @click="back" class="fanhui" name="arrow-left" />
      <span>本地储值金（共{{list.length}}个门店）</span>
    </div>

    <div style="padding-top:46px; text-align: center;">
      <div class="lis" v-for="(item,idx) in list" :key="idx" @click="toxiangqin(item)">
        <div class="name_s">
          <div class="left">
            <img class="menimg" src="../../assets/img/md2.png" alt />
            <span style="vertical-align: middle;font-weight: bold">{{item.garageName}}</span>
          </div>
        </div>
        <div class="name_s" style="color:#666;font-size: 14px;">
          <div class="left" style="margin-left: 27px;">余额</div>
          <div class="right balac">
            <span>￥</span>
            <span>{{item.balance}}</span>
            <span style="margin-left: 15px;">></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: []
    };
  },
  methods: {
    toxiangqin(item) {
      this.$router.push({
        path: "water_subsidiary",
        query: {
          isok: 'true',
          customerId: item.customerId,
          gid: item.gid
        }
      });
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        gid: this.shangjia.id
      };
      orderModel
        .storelist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      //   this.page++;
      this.onLoad();
    },

    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });f
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
// 标题
.biao_ti {
  height: 46px;
  background: #df3447;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  color: #fff;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}
.menimg {
  width: 18px;
  vertical-align: middle;
  margin-right: 10px;
}
.lis {
  overflow: hidden;
  font-size: 15px;
  //   border-bottom: px solid #f5f5f5;
  background: #fff;
  padding: 5px 12px;
  margin-top: 7px;
}
.balac {
  font-weight: bold;
  color: #000;
  font-size: 14px;
}
.name_s {
  overflow: hidden;
  line-height: 30px;
}
</style>